import { lazy, Suspense } from "preact/compat";
import Preloader from "./components/Preloader";
import "./style";

const LazyApp = lazy(() => import('./components/app'));

export default () => (
  <Suspense fallback={<Preloader/>}>
    <LazyApp />
  </Suspense>
);
